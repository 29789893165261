import { useNotebook, useSelectedNote } from "../hook";

export const useSelectMainNote: () => (config?: {
  notebook?: any;
}) => void = () => {
  const [notebookState, setNotebook] = useNotebook();
  const [selectedNote, setSelectedNote] = useSelectedNote();

  return (config = {}) => {
    const { notebook: notebookParam } = config;
    const notebook = notebookParam ?? notebookState;
    const note = notebook.pages[0]["page-data"];
    setSelectedNote(note);
  };
};
