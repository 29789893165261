import { Paper, Typography } from "@mui/material";
import { usePushConnectorForm } from "../../../hook/pushConnector/edit";
import { PushConnectorFormFields } from "./components";
import { PushConnectorFormField } from "../../../reducer";

const R = require("ramda");

export const PushConnectorEditPage: React.FC = () => {
  const [pushConnectorForm, setPushConnector] = usePushConnectorForm();
  const nameField: PushConnectorFormField = R.find(
    R.compose(R.equals("name"), R.prop("label")),
    pushConnectorForm
  );
  const name = nameField.value;

  return (
    <Paper
      sx={{ padding: 3, gap: 3, display: "flex", flexDirection: "column" }}
    >
      <Typography variant="h3">{name}</Typography>
      <PushConnectorFormFields pushConnectorForm={pushConnectorForm} />
    </Paper>
  );
};
