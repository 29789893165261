import AddIcon from "@mui/icons-material/Add";
import {
  Avatar,
  Box,
  Card,
  CardContent,
  Grid,
  IconButton,
  Typography,
} from "@mui/material";
import { useConnectorTypes, useSelectedConnectorType } from "../../hook";

export const SelectConnectCardPage: React.FC = () => {
  const [connectorTypes, setConnectorTypes] = useConnectorTypes();
  const [selectedConnectorType, setSelectedConnectorType] =
    useSelectedConnectorType();

  return (
    <Grid container spacing={3}>
      {connectorTypes.map((connectorType: any) => (
        <Grid item xs={12} sm={6} md={4} lg={3} key={connectorType.id}>
          <Card
            sx={{
              height: "12rem",
              justifyContent: "center",
            }}
          >
            <CardContent
              sx={{
                display: "flex",
                flexDirection: "column",
                textAlign: "center",
                height: "100%",
                justifyContent: "space-between",
              }}
            >
              <Avatar
                alt={connectorType.name}
                src={connectorType.icon_url}
                style={{ width: "3rem", height: "3rem", margin: "0 auto" }}
              />
              <Typography variant="body1" style={{ marginTop: 10 }}>
                {connectorType.name}
              </Typography>
              <Box>
                <IconButton
                  onClick={() => {
                    setSelectedConnectorType(connectorType);
                  }}
                  color="primary"
                >
                  <AddIcon />
                </IconButton>
              </Box>
            </CardContent>
          </Card>
        </Grid>
      ))}
    </Grid>
  );
};
