import { toEDNString } from "edn-data";
import { PushConnector, PushConnectorData, Table, TableSpec } from "./reducer";

const R = require("ramda");

const omegaUrl = process.env.REACT_APP_OMEGA_URL;

export const queryOmega = async (query: string) => {
  const response = await fetch(`${omegaUrl}/query_clj`, {
    method: "POST",
    headers: { "Content-Type": "text/plain" },
    body: query,
  });
  const jsonResponse = await response.json();
  return jsonResponse["result"];
};

export const queryOmegaSingleResult = async (query: string) => {
  const response = await queryOmega(query);
  return response["data"][0][0];
};

export const getConnectorTypes = async () => {
  const query = `
      (datastore Api "omega/query-omega/public/api")
      (Api/get-connector-types {} Response)
      (return [Response])
    `;
  const response = await queryOmegaSingleResult(query);
  return response["connector-types"];
};

export const getNotebook: (config: { appId: string }) => Promise<any> = async ({
  appId,
}) => {
  const ednString = toEDNString({
    map: [["app-id", appId]],
  });
  const query = `
      (datastore Api "omega/query-omega/public/api")
      (Api/get-pages ${ednString} Response)
      (return [Response])
    `;
  const response = await queryOmegaSingleResult(query);
  return response;
};

export const getTable: (config: {
  appId: string;
  tableId: string;
}) => Promise<Table> = async ({ appId, tableId }) => {
  const ednString = toEDNString({
    map: [
      ["app-id", appId],
      ["table-id", tableId],
    ],
  });
  const query = `
      (datastore Api "omega/query-omega/public/api")
      (Api/search-table ${ednString} Response)
      (return [Response])
    `;
  const response = await queryOmegaSingleResult(query);
  return response;
};

export const getTableSpec: (config: {
  appId: string;
  tableId: string;
}) => Promise<TableSpec> = async ({ appId, tableId }) => {
  const ednString = toEDNString({
    map: [
      ["app-id", appId],
      ["table-id", tableId],
    ],
  });
  const query = `
      (datastore Api "omega/query-omega/public/api")
      (Api/get-table-spec ${ednString} Response)
      (return [Response])
    `;
  const response = await queryOmegaSingleResult(query);
  return response;
};

export const getApps: (config: { authToken: string }) => any = async ({
  authToken,
}) => {
  const ednString = toEDNString({
    map: [["auth-token", authToken]],
  });
  const query = `
      (datastore Api "omega/query-omega/public/api")
      (Api/get-apps ${ednString} Response)
      (return [Response])
    `;
  const response = await queryOmegaSingleResult(query);
  return response;
};

export const getConnectors: (config: {
  appId: string;
}) => Promise<any> = async ({ appId }) => {
  const ednString = toEDNString({
    map: [["app-id", appId]],
  });
  const query = `
      (datastore Api "omega/query-omega/public/api")
      (Api/get-connectors ${ednString} Response)
      (return [Response])
    `;
  const response = await queryOmegaSingleResult(query);
  return response;
};

export const deletePullConnector: (config: {
  appId: string;
  connectorId: string;
}) => Promise<any> = async ({ appId, connectorId }) => {
  const ednString = toEDNString({
    map: [
      ["app-id", appId],
      ["connector-id", connectorId],
    ],
  });
  const query = `
      (datastore Api "omega/query-omega/public/api")
      (Api/delete-connector ${ednString} Response)
      (return [Response])
    `;
  const response = await queryOmegaSingleResult(query);
  return response;
};

export const getPushConnectors: (config: {
  appId: string;
}) => Promise<any> = async ({ appId }) => {
  const ednString = toEDNString({
    map: [["app-id", appId]],
  });
  const query = `
      (datastore Api "omega/query-omega/public/api")
      (Api/get-push-connectors ${ednString} Response)
      (return [Response])
    `;
  const response = await queryOmegaSingleResult(query);
  return response;
};

export type ConnectorTableResponse = {
  "connector-id": string;
  tables: Table[];
};

export const getConnectorTables: (config: {
  appId: string;
  connectorId: string;
}) => Promise<ConnectorTableResponse> = async ({ appId, connectorId }) => {
  const ednString = toEDNString({
    map: [
      ["app-id", appId],
      ["connector-id", connectorId],
    ],
  });
  const query = `
      (datastore Api "omega/query-omega/public/api")
      (Api/get-connector-tables ${ednString} Response)
      (return [Response])
    `;
  const response = await queryOmegaSingleResult(query);
  return response;
};

export const deleteConnector: (config: {
  schema: string;
}) => Promise<any> = async ({ schema }) => {
  const ednString = toEDNString({
    map: [["schema", schema]],
  });
  const query = `
      (datastore Api "omega/query-omega/public/api")
      (Api/delete-connector ${ednString} Response)
      (return [Response])
    `;
  const response = await queryOmegaSingleResult(query);
  return response;
};

export const createPushConnector: (config: {
  appId: string;
  pushConnector: PushConnectorData;
}) => Promise<any> = async ({ appId, pushConnector }) => {
  const ednString = toEDNString({
    map: [
      ["app-id", appId],
      ["push-connector", { map: Object.entries(pushConnector) }],
    ],
  });
  const query = `
      (datastore Api "omega/query-omega/public/api")
      (Api/create-push-connector ${ednString} Response)
      (return [Response])
    `;
  const response = await queryOmegaSingleResult(query);
  return response;
};

export const deletePushConnector: (config: {
  appId: string;
  pushConnectorId: string;
}) => Promise<any> = async ({ appId, pushConnectorId }) => {
  const ednString = toEDNString({
    map: [
      ["app-id", appId],
      ["push-connector-id", pushConnectorId],
    ],
  });
  const query = `
      (datastore Api "omega/query-omega/public/api")
      (Api/delete-push-connector ${ednString} Response)
      (return [Response])
    `;
  const response = await queryOmegaSingleResult(query);
  return response;
};

export const deletePageBlock: (
  pageId: string,
  blockIndex: number
) => Promise<any> = async (pageId, blockIndex) => {
  const ednString = toEDNString({
    map: [
      ["note-id", pageId],
      ["block-index", blockIndex],
    ],
  });
  const query = `
      (datastore Api "omega/query-omega/public/api")
      (Api/delete-note-block ${ednString} Response)
      (return [Response])
    `;
  const response = await queryOmegaSingleResult(query);
  return response["new-note"];
};

export const createConnectCard: (config: {
  schema: string;
  appId: string;
}) => Promise<any> = async ({ schema, appId }) => {
  const ednString = toEDNString({
    map: [
      ["app-id", appId],
      ["schema", schema],
    ],
  });
  const query = `
      (datastore Api "omega/query-omega/public/api")
      (Api/create-connect-card ${ednString} Response)
      (return [Response])
    `;
  const response = await queryOmegaSingleResult(query);
  return response;
};

export const createConversation: (config: {
  appId: string;
}) => Promise<any> = async ({ appId }) => {
  const ednString = toEDNString({
    map: [["app-id", appId]],
  });
  const query = `
      (datastore Api "omega/query-omega/public/api")
      (Api/create-conversation ${ednString} Response)
      (return [Response])
    `;
  const response = await queryOmegaSingleResult(query);
  return response;
};

export const replyConversation: (config: {
  content: string;
  chatId: string;
}) => Promise<any> = async ({ content, chatId }) => {
  const ednString = toEDNString({
    map: [
      ["content", content],
      ["chat-id", chatId],
    ],
  });
  const query = `
      (datastore Api "omega/query-omega/public/api")
      (Api/reply-conversation ${ednString} Response)
      (return [Response])
    `;
  const response = await queryOmegaSingleResult(query);
  return response;
  // return new Promise((resolve, reject) => {
  //   setTimeout(() => {
  //     resolve({
  //       "chat-id": "be93e9d9-a2b4-4716-9228-f76fd182010b",
  //       reply:
  //         'I have created a script that processes the "transformed_go_high_level_data" table to extract full names. Here\'s what the script does:\n\n1. **Extracts the Input Table**: It takes the "transformed_go_high_level_data" table, which contains columns like email, first name, and last name.\n2. **Creates a Full Name Column**: It combines the first name and last name columns to create a new column called "full_name".\n3. **Selects Required Columns**: It then selects only the email and full_name columns to create a new table.\n4. **Stores the Result**: The new table, named "full_names_output", contains the email and full names of the contacts.\n\nThe result is saved in a variable called `result`, which contains the new table with the specified columns.',
  //     });
  //   }, 2000);
  // });
};

export const deleteTable: (config: {
  tableId: string;
}) => Promise<any> = async ({ tableId }) => {
  const ednString = toEDNString({
    map: [["table-id", tableId]],
  });
  const query = `
      (datastore Api "omega/query-omega/public/api")
      (Api/delete-table ${ednString} Response)
      (return [Response])
    `;
  const response = await queryOmegaSingleResult(query);
  return response;
};

export const fetchTableCount: (config: {
  tableId: string;
  appId: string;
}) => Promise<{
  "table-id": string;
  "app-id": string;
  count: number;
}> = async ({ tableId, appId }) => {
  const ednString = toEDNString({
    map: [
      ["table-id", tableId],
      ["app-id", appId],
    ],
  });
  const query = `
      (datastore Api "omega/query-omega/public/api")
      (Api/get-table-count ${ednString} Response)
      (return [Response])
    `;
  const response = await queryOmegaSingleResult(query);
  return response;
};

export const fetchTablePage: (config: {
  tableId: string;
  appId: string;
  index: number;
  pageSize: number;
}) => Promise<{
  "table-id": string;
  "app-id": string;
  index: number;
  "page-size": number;
  rows: string[][];
}> = async ({ tableId, appId, index, pageSize }) => {
  const ednString = toEDNString({
    map: [
      ["table-id", tableId],
      ["app-id", appId],
      ["index", index],
      ["page-size", pageSize],
    ],
  });
  const query = `
      (datastore Api "omega/query-omega/public/api")
      (Api/get-table-page ${ednString} Response)
      (return [Response])
    `;
  const response = await queryOmegaSingleResult(query);
  return response;
};
