import {
  Box,
  CircularProgress,
  Table as MuiTable,
  Paper,
  Stack,
  Tab,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Tabs,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import ReactMarkdown from "react-markdown";
import SyntaxHighlighter from "react-syntax-highlighter";
import { tomorrowNight } from "react-syntax-highlighter/dist/esm/styles/hljs";
import remarkGfm from "remark-gfm";
import { fetchTableCount, fetchTablePage } from "../api";
import { useAppId, useTableSpec } from "../hook";
import { Table, TableSpec } from "../reducer";

export const TableTab: React.FC<{ table: Table }> = ({ table }) => {
  const tableId = table?.["table-id"];
  const [index, setIndex] = useState<number>(0);
  const [rows, setRows] = useState<string[][]>([]);
  const header = table?.header ?? [];
  const [isLoading, setIsLoading] = useState(true);
  const page = index;
  const setPage = (page: number) => setIndex(page);
  const [tableCount, setTableCount] = useState<number>(0);
  const [appId, setAppId] = useAppId();

  useEffect(() => {
    if (tableCount || !appId || !tableId) return;

    fetchTableCount({ appId: appId, tableId }).then((res) => {
      setTableCount(res.count);
    });
  }, [tableCount, appId, tableId]);

  useEffect(() => {
    if (index === 0 || !tableId || !appId) return;

    setIsLoading(true);
    fetchTablePage({ appId, index, tableId, pageSize: 10 }).then((res) => {
      setRows(res.rows);
      setIsLoading(false);
    });
  }, [tableId, index]);

  useEffect(() => {
    if (!table?.rows) return;

    setIsLoading(false);
    setRows(table.rows);
  }, [table?.rows?.length]);

  return (
    <>
      <TableContainer component={Paper}>
        <MuiTable>
          <TableHead>
            <TableRow>
              {header?.map((name: any, index: number) => (
                <TableCell sx={{ height: "4rem" }} key={index}>
                  {name}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          {!isLoading && (
            <TableBody>
              {rows?.map((row: any, rowIndex: number) => (
                <TableRow key={rowIndex}>
                  {row.map((cell: any, cellIndex: number) => (
                    <TableCell sx={{ height: "4rem" }} key={cellIndex}>
                      {cell}
                    </TableCell>
                  ))}
                </TableRow>
              ))}
            </TableBody>
          )}
        </MuiTable>
      </TableContainer>
      {isLoading && (
        <Box
          sx={{
            height: "40rem",
            width: "100%",
            display: "flex",
            justifyContent: "center",
          }}
        >
          <CircularProgress />
        </Box>
      )}
      <TablePagination
        component="div"
        onPageChange={(e, value) => setPage(value)}
        rowsPerPage={10}
        rowsPerPageOptions={[10]}
        page={page}
        count={tableCount}
        showFirstButton={true}
        showLastButton={true}
      />
    </>
  );
};

export const CodeTab: React.FC<{ tableSpec?: TableSpec }> = ({ tableSpec }) => {
  return (
    <Box sx={{ overflowY: "auto" }}>
      <ReactMarkdown
        components={{
          code(props) {
            const { children, className, node, ...rest } = props;
            const match = /language-(\w+)/.exec(className || "");
            return match ? (
              <SyntaxHighlighter language={match[1]!} style={tomorrowNight}>
                {String(children)}
              </SyntaxHighlighter>
            ) : (
              <code {...rest} className={className}>
                {children}
              </code>
            );
          },
        }}
        remarkPlugins={[remarkGfm]}
      >
        {tableSpec?.code}
      </ReactMarkdown>
    </Box>
  );
};

export const SearchTableTabs: React.FC<{
  table: Table;
}> = ({ table }) => {
  const tableId = table?.["table-id"];
  const tableSpec = useTableSpec(tableId);
  const [selectedTab, setSelectedTab] = useState(0);

  return (
    <>
      <Tabs value={selectedTab}>
        <Tab color="primary" label="Table" onClick={() => setSelectedTab(0)} />
        <Tab color="primary" label="Code" onClick={() => setSelectedTab(1)} />
      </Tabs>
      {selectedTab === 0 ? (
        <TableTab table={table} />
      ) : (
        <CodeTab tableSpec={tableSpec} />
      )}
    </>
  );
};

export const SearchTable: React.FC<{ table: Table; codeTable?: boolean }> = ({
  table,
  codeTable = false,
}) => {
  return (
    <Stack spacing={2} sx={{ height: "44rem" }}>
      {codeTable ? (
        <SearchTableTabs table={table} />
      ) : (
        <TableTab table={table} />
      )}
    </Stack>
  );
};
