import { useAuth0 } from "@auth0/auth0-react";
import AutoAwesomeIcon from "@mui/icons-material/AutoAwesome";
import {
  AppBar,
  Box,
  Button,
  IconButton,
  Toolbar,
  Typography,
} from "@mui/material";
import { useAppId, useSideNavOpen, useToggleChatDrawerOpen } from "../hook";
import { ToggleSideNavButton } from "./sideNav";

export const AppBarSection: React.FC<{ drawerWidth: string }> = ({
  drawerWidth,
}) => {
  const [sideNavOpen] = useSideNavOpen();
  const [appId, setAppId] = useAppId();
  const toggleChatDrawerOpen = useToggleChatDrawerOpen();
  const { loginWithPopup, user, logout, isAuthenticated } = useAuth0();
  const email = user?.email;

  return (
    <AppBar
      sx={{
        transition: (theme) =>
          theme.transitions.create(["width", "margin"], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
          }),
        width: sideNavOpen ? `calc(100% - ${drawerWidth})` : "100%",
        marginLeft: sideNavOpen ? `${drawerWidth}` : 0,
      }}
    >
      <Toolbar>
        {appId && !sideNavOpen && <ToggleSideNavButton />}
        <IconButton edge="start" color="inherit" aria-label="app icon">
          <img src={`omega_logo.png`} alt="app icon" style={{ height: 24 }} />
        </IconButton>
        <Box sx={{ flexGrow: 1 }} />
        {!isAuthenticated && (
          <Button
            onClick={() => {
              loginWithPopup();
            }}
          >
            Login
          </Button>
        )}
        {isAuthenticated && <Typography>{email}</Typography>}
        {isAuthenticated && (
          <Button
            onClick={() => {
              logout({
                logoutParams: {
                  returnTo: window.location.origin,
                },
              });
            }}
          >
            Logout
          </Button>
        )}
        {appId && isAuthenticated && (
          <IconButton
            aria-label="Open QueryOmega AI Chat"
            onClick={toggleChatDrawerOpen}
            color="primary"
          >
            <AutoAwesomeIcon />
          </IconButton>
        )}
      </Toolbar>
    </AppBar>
  );
};
