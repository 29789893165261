import { Link, Paper } from "@mui/material";

export const NoWorkspacePage: React.FC = () => {
  return (
    <Paper sx={{ padding: 3 }}>
      No Workspaces are associated with this account. Contact{" "}
      <Link href={"mailto:admin@queryomega.com"}>admin@queryommega.com</Link> to enable a premium plan to get workspace access.
    </Paper>
  );
};
