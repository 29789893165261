import { Box, Button, IconButton, TextField } from "@mui/material";
import {
  useAddPushConnectorFormField,
  useDeleteSelectedPushConnector,
  useRemovePushConnectorFormField,
  useSetPushConnectorFormField,
  useSubmitPushConnectorCreateForm,
} from "../../../hook/pushConnector/edit";
import { PushConnectorFormField } from "../../../reducer";

import DeleteIcon from "@mui/icons-material/Delete";

export const PushConnectorFormFieldComponent: React.FC<{
  field: PushConnectorFormField;
  disabled?: boolean;
  index: number;
}> = ({ field: { label, value }, disabled = false, index }) => {
  const labelDisabled = disabled || label === "description" || label === "name";
  const isDescription = label === "description";
  const setPushConnectorFormField = useSetPushConnectorFormField();
  const removePushConnectorFormField = useRemovePushConnectorFormField();
  return (
    <Box sx={{ display: "flex", gap: 3, justifyContent: "space-between" }}>
      <TextField
        sx={{ display: "flex", flexGrow: 1 }}
        label={"label"}
        value={label}
        disabled={labelDisabled}
        onChange={(e) => {
          setPushConnectorFormField({
            field: { label: e.target.value, value },
            index,
          });
        }}
      />
      <TextField
        sx={{ display: "flex", flexGrow: 1 }}
        label={"value"}
        value={value}
        disabled={disabled}
        multiline={isDescription}
        onChange={(e) => {
          setPushConnectorFormField({
            field: { label, value: e.target.value },
            index,
          });
        }}
      />
      {!labelDisabled && (
        <IconButton
          onClick={() => {
            removePushConnectorFormField(index);
          }}
        >
          <DeleteIcon color="primary" />
        </IconButton>
      )}
    </Box>
  );
};

export const PushConnectorFormFields: React.FC<{
  pushConnectorForm: PushConnectorFormField[];
  disabled?: boolean;
}> = ({ pushConnectorForm, disabled = false }) => {
  const addPushConnectorFormField = useAddPushConnectorFormField();
  const submitPushConnectorCreateForm = useSubmitPushConnectorCreateForm();
  const deleteSelectedPushConnector = useDeleteSelectedPushConnector();

  return (
    <Box sx={{ display: "flex", flexDirection: "column", gap: 6 }}>
      {pushConnectorForm.map((field, index) => (
        <PushConnectorFormFieldComponent
          field={field}
          key={index}
          index={index}
          disabled={disabled}
        />
      ))}
      <Box sx={{ display: "flex", justifyContent: "end" }}>
        {!disabled && (
          <>
            <Button onClick={addPushConnectorFormField} color="primary">
              Add Field
            </Button>
            <Button onClick={submitPushConnectorCreateForm} color="primary">
              Save
            </Button>
          </>
        )}
        {disabled && (
          <>
            <IconButton onClick={deleteSelectedPushConnector} color="primary">
              <DeleteIcon />
            </IconButton>
          </>
        )}
      </Box>
    </Box>
  );
};
