import { useMediaQuery, useTheme } from "@mui/material";

export const useIsMediumScreen = () => {
  const theme = useTheme();
  const isMediumScreen = useMediaQuery(theme.breakpoints.down("md"));
  return isMediumScreen;
};

export const useDrawerWidth = () => {
  const isMediumScreen = useIsMediumScreen();
  return isMediumScreen ? "15rem" : "20rem";
};
