import React from "react";
import { useDeleteNoteTable, useSelectedNote, useTable } from "../../hook";
import { NoteBlockContainer, NotePageContainer } from "../note";
import { SearchTable } from "../searchTable";

export const NoteBlock: React.FC<{ block: any }> = ({ block }) => {
  const tableId = block?.["table-id"];
  const table = useTable(tableId)!;
  const name = table?.name;
  const deleteNoteTable = useDeleteNoteTable();
  return (
    <NoteBlockContainer
      name={name}
      onDelete={() => {
        deleteNoteTable({ tableId });
      }}
    >
      <SearchTable table={table} codeTable={true} />
    </NoteBlockContainer>
  );
};

export const NotePage: React.FC = () => {
  const [selectedNote, setSelectedNote] = useSelectedNote();
  const blocks = selectedNote?.blocks ?? [];
  const pageName = selectedNote?.name;
  return (
    <NotePageContainer pageName={pageName!}>
      {blocks?.map((block: any, index: number) => (
        <NoteBlock block={block} key={index} />
      ))}
    </NotePageContainer>
  );
};
